import { useContext } from 'react'
import { StoreContext } from '@/providers/storeProvider'
import { MarketCode } from '@/network/graphql.g'
import { DateTime, Interval } from 'luxon'
import { useRouter } from 'next/router'
import { Routes } from '@/core/routes'
import {
  ExperimentType,
  PricePercentageVariant,
  useVariant
} from '@/providers/experimentProvider'

type AssetFunction = (path: Asset, includeFilatic?: boolean) => string

type UseAsset = {
  asset: AssetFunction
  isCountryForDogSmileFace: boolean
  isChristmasPromoTime: boolean
  isShutDownCountry: boolean
  enabledMarketReturnUnderwear: boolean
  isClusterVolumeAboutUsPopup: boolean
  isCartProcessStep: boolean
  dachRegionCountries: boolean
  hidePaymentIcons: boolean
  shouldShowNewPrices: boolean
}

const getAsset =
  (domainName: string, isFilatic: boolean): AssetFunction =>
  (path, includeFilatic = false) =>
    path
      ? `/${includeFilatic && isFilatic ? 'filatic' : domainName}/${path}`
      : ''

export const useAsset = (): UseAsset => {
  const {
    subdomainName,
    domainName,
    isFilatic,
    isFactcool,
    isAliatic,
    isFrogies,
    isBezvasport
  } = useContext(StoreContext)

  const isCountryForDogSmileFace =
    isFactcool &&
    [
      MarketCode.Sk,
      MarketCode.Cs,
      MarketCode.Hr,
      MarketCode.Gr,
      MarketCode.Bg
    ].includes(subdomainName as MarketCode)

  const currentDate = DateTime.now()
  const startDate = DateTime.local(2023, 9, 1, 0, 1)
  const endDate = DateTime.local(2023, 12, 24, 23, 59)
  const isChristmasPromoTime = Interval.fromDateTimes(
    startDate,
    endDate
  ).contains(currentDate)

  const isShutDownCountry =
    (isFactcool || isAliatic || isFrogies) &&
    [
      //disabled 1.1.2024
      MarketCode.Es,
      MarketCode.Fr,
      MarketCode.It,
      MarketCode.Pt,
      MarketCode.Dk,
      MarketCode.Fi,
      MarketCode.Se,
      //disabled 2025
      MarketCode.Nl,
      MarketCode.De,
      MarketCode.At,
      MarketCode.Gr,
      MarketCode.Be
    ].includes(subdomainName as MarketCode)

  const enabledMarketReturnUnderwear = [MarketCode.Ba, MarketCode.Rs].includes(
    subdomainName as MarketCode
  )

  const isClusterVolumeAboutUsPopup = [
    MarketCode.Lv,
    MarketCode.Lt,
    MarketCode.Ee,
    MarketCode.Pl
  ].includes(subdomainName as MarketCode)

  const dachRegionCountries = [
    MarketCode.De,
    MarketCode.At,
    MarketCode.Nl
  ].includes(subdomainName as MarketCode)

  const hidePaymentIcons = [MarketCode.Rs, MarketCode.Ba].includes(
    subdomainName as MarketCode
  )

  const router = useRouter()

  const isCartProcessStep =
    router.route === Routes.cart ||
    router.route === Routes.deliveryCost ||
    router.route === Routes.deliveryAddress

  const pricePercentage = useVariant(ExperimentType.PricePercentage)
  const shouldShowNewPrices =
    isBezvasport &&
    subdomainName === MarketCode.Cs &&
    pricePercentage === PricePercentageVariant.VariantA

  return {
    asset: getAsset(domainName, isFilatic),
    isCountryForDogSmileFace,
    isChristmasPromoTime,
    isShutDownCountry,
    enabledMarketReturnUnderwear,
    isClusterVolumeAboutUsPopup,
    isCartProcessStep,
    dachRegionCountries,
    hidePaymentIcons,
    shouldShowNewPrices
  }
}

export enum Asset {
  Logo = 'logo.webp',
  ErrorLogo = 'error-logo.webp',
  InvertedLogo = 'inverted-logo.webp',
  MobileMenuLogo = 'mobile-menu-logo.webp',
  BGDropdown = 'bg-dropdown.webp',
  Insurance = 'insurance.png',
  Packatory = 'packatory.png',
  Favicon = 'favicon.png',
  BGNotFound = 'bg-lines-dark.webp',
  toolbarBackground = 'toolbar-background.webp',
  SadDog = 'sad-dog.webp',
  HappyDog = 'happy-dog.webp'
}
